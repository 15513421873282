 <template>
  <div>
    <CModal color="primary" :show="showModal">
      <template v-slot:header>
        <h5 class="modal-title">Product Model Details</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(showModal)"
        >
          ×
        </button>
      </template>          
      <p>
        Product Model:
        {{ product_model ? product_model.name : null }}
      </p>     
      <p>
        Group:
        {{ product_model ? product_model.product_group.name : null }}
      </p>     
      <p>
        Brand:
        {{ product_model ? product_model.brand.name : null }}
      </p>     
      <p>
        Category:
        {{ product_model ? product_model.product_category.name : null }}
      </p>     
      <p>
        Description:
        {{ product_model ? product_model.description : null }}
      </p>     
     
      <template v-slot:footer>
        <CButton color="primary" @click="closeModal(showModal)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductModelView",
  computed: {
    ...mapState({
      showModal: (state) => state.product_models.showModal,
      product_model: (state) => state.product_models.showProductModel,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("product_models/SHOW_MODAL", {
        bool: bool,
         product_model: null,
      });
    },
  },
};
</script>